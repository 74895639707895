import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Slider from "react-slick"

import ImagePropis from "../images/ruskids-cover.jpg"

import russian1 from "../images/ruskids-1.jpg"
import russian2 from "../images/ruskids-2.jpg"
import russian3 from "../images/ruskids-3.jpg"
import russian4 from "../images/ruskids-4.jpg"
import OrderRusKids from "../components/orderRuskids"

const imgZhi = [
  {
    id: 1,
    pic: russian1,
  },
  {
    id: 2,
    pic: russian2,
  },
  {
    id: 3,
    pic: russian3,
  },
  {
    id: 4,
    pic: russian4,
  },
]
const RusKids = () => {
  const imageMap = imgZhi.map(image => {
    return (
      <div key={image.id}>
        <div className="flex justify-center">
          <img className="max-h-350" src={image.pic} alt="some" />
        </div>
      </div>
    )
  })

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }
  return (
    <Layout>
      <Seo title="Прописи русский язык" />
      <div className="mb-4">
        <a href="/">
          <button
            type="button"
            class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center"
          >
            На главную
          </button>
        </a>
      </div>
      <div className="flex flex-col md:flex-row">
        <div className="md:w-1/2">
          <img src={ImagePropis} alt="cover"/>
        </div>
        <div className="pl-0 md:pl-8 md:w-1/2">
          <h1 className="mb-4 text-2xl font-black tracking-tighter text-black md:text-5xl title-font">
            Прописи русский язык
          </h1>
          <p className="mt-12 mb-0">
            Прописи по русскому языку для малышей и дошкольников содержат ряд
            последовательных упражнений для развития мелкой моторики по принципу
            от простого к сложному.
          </p>
          <br />
          <span className="">
            В первой половине тетради ребенок работает самостоятельно, чертит и
            рисует различные линии и элементы. Все строчки и фигуры сначала
            большие, потом поменьше. Развиваем моторику, четкость,
            внимательность.
          </span>
          <br />
          <br />
          <span>
            Во второй половине прописи ребенок изучит письменную речь - печатные
            буквы, которые также сначала большие, потом поменьше и короткие
            слова. Здесь ребенку может понадобиться помощь взрослого, чтоб
            объяснить как звучит каждая буква.
          </span>
          <br />
          <br />
          <span>
            Занятия в прописи очень полезны для развития малышей. Ребенок
            разрабатывает руку, пальцы, запоминает начертание различных фигур и
            букв, учится писать первые слова.Освоение печатного письма в рабочей
            тетради происходит плавно и поэтапно. Ваш ребенок развивает навыки
            письма, усидчивость, внимательность, координацию, память, мышление,
            речь, зрительное восприятие, творческие способности, графические
            навыки.
          </span>
          <br />
          <br />
          <span>Учите правило и тренируйте почерк!!!</span>
        </div>
      </div>
      <OrderRusKids />
      <div className="mt-24 mb-24">
        <Slider {...settings}>{imageMap}</Slider>
      </div>
    </Layout>
  )
}

export default RusKids
